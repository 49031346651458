/* @import url(./darktheme.css); */

.sidebar {
    min-width: 300px;
    flex: 40%;
    height: 100% !important;
    border-right: 1px solid #DADADA;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    box-sizing: border-box;
}

.sidebarWrapper {
    margin: 12px;
    height: 90%;
    overflow: auto;
}

.chatPrompt .question-content {
    color: #000;
    font-family: 'Raleway', sans-serif;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal;
}

.sidebarWrapper .promptsHeading {
    color: #000;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
}

/*  Sidebar Header */

.sidebar__avatar-wrapper {
    width: 40px;
    height: 40px;
}

.sidebar__actions {
    margin-right: 20px;
}

.sidebar__actions>* {
    display: inline-block;
    margin-left: 25px;
    cursor: pointer;
}

.sidebar__action-icon {
    color: rgb(145, 145, 145);
}

/* End Sidebar Header */

/*  Sidebar Alert */

.sidebar__alert {
    min-height: 85px;
    padding: 20px;
    display: flex;
    align-items: center;
}

.sidebar__alert--warning {
    background: #FED859;
}

.sidebar__alert--info {
    background: #9DE1FE;
}

.sidebar__alert--danger {
    background: #F3645B;
}

.sidebar__alert-icon-wrapper {
    margin-right: 10px;
}

.sidebar__alert-icon {
    color: white;
}

.sidebar__alert-texts {
    flex: 1;
}

.sidebar__alert-text:first-of-type {
    font-size: 1rem;
    margin-bottom: 5px;
    color: #343738;
}

.sidebar__alert-text:last-of-type {
    font-size: 0.85rem;
    color: #414A4E;
    line-height: 17px;
}

.sidebar__alert--danger .sidebar__alert-text:first-of-type,
.sidebar__alert--danger .sidebar__alert-text:last-of-type {
    color: white;
}

/* End Sidebar Alert */

/*  Sidebar Search */

.sidebar__search-wrapper {
    padding: 7px 10px;
    height: 50px;
    background: #F6F6F6;
    position: relative;
}

/* End Sidebar Search */

/*  Sidebar Contact List */

.sidebar__contacts {
    flex: 1;
    overflow-y: scroll;
    background: #F5F5F5;
    border-top: 1px solid #DADADA;
    position: relative;
}

.sidebar__explore-content {
    height: 100%;
    background-color: #ffffff;
}

.promptsHeading {
    color: #000;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 16px;
}

.sidebar-contact {
    border: 1px solid #EBEBEB;
    height: 70px;
    display: flex;
    margin-bottom: 12px;
    padding: 8px 16px;
    box-sizing: border-box;
    cursor: pointer;
    border-radius: 15px;
}

.settingMenu div {
    color: var(--black, #0D0D0D);
    font-family: 'Raleway', sans-serif;
    font-size: 12px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal;
}

.settingMenu .submenu {
    min-width: 100px;
    height: 120px;
    max-height: 120px;
    border: 1px solid #E5E5E5;
    background: var(--white, #FCFCFC);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 120px;
    max-width: 140px;
    overflow-y: auto;
    position: absolute;
    left: 94%;
    top: 90%;
}

.settingMenu .submenu div {
    padding: 6px 8px;
}

.settingMenu .submenu div:hover {
    background-color: #cccccca4;
}

.settingMenu .submenu div:first-child {
    margin-top: 6px;
}

.sidebar-contact:hover {
    background-color: #EBEBEB;
}

.sidebar-contact__avatar-wrapper {
    width: 50px;
    height: 50px;
    margin-right: 10px;
}

.sidebar-contact__content {
    /* overflow: hidden; */
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sidebar-contact__content .sidebar-contact__top-content {
    width: 80%;
}

/* .sidebar-contact__top-content,
.sidebar-contact__bottom-content,
.sidebar-contact__message-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
} */

.sidebar-contact__name,
.sidebar-contact__message {
    flex: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-bottom: 0;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
}

/* .sidebar-contact__top-content {
    margin-bottom: 2px;
} */

.sidebar-contact__name {
    color: #000000;
    font-size: 1rem;
    font-weight: 600;
    font-family: 'Raleway', sans-serif;
}

.sidebar-contact__time {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: space-around;
}

.truncateUserName {
    width: auto;
    position: relative;
}

.settingMenu {
    position: absolute;
    right: 5px;
    top: 22px;
    z-index: 9999;
    border: 1px solid #E5E5E5;
    background: var(--white, #FCFCFC);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 16px;
    width: 107px;
    height: 92;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.deleteChatBtn {
    height: 14px;
}

.userChatIcon svg {
    vertical-align: baseline;
    margin-right: 6px;
}

.chatSpinner {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid #ccc;
    border-top: 4px solid #3498db;
    animation: spin 1s linear infinite;
    margin: 20px auto;
    position: absolute;
    top: 30%;
    left: 48%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.sidebar-contact__message-wrapper {
    color: #00000099;
    font-size: 0.85rem;
    margin-right: 3px;
    overflow: hidden;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.sidebar-contact__message-icon {
    color: #B3B3B3;
    margin-right: 3px;
}

.sidebar-contact__message-icon--blue {
    color: #0DA9E5;
}

.sidebar-contact__message--unread {
    color: #000000;
    font-weight: 500;
}

.sidebar-contact__icons,
.sidebar-contact:not(:focus) .sidebar-contact__icons {
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateX(24px);
    transition: transform 0.5s ease;
}

.sidebar-contact:hover .sidebar-contact__icons {
    transform: translateX(0);
}

.sidebar-contact__icons>* {
    margin-left: 8px;
    color: #B3B3B3;
}

.sidebar-contact__unread {
    display: inline-block;
    color: white;
    background-color: rgb(6, 215, 85);
    border-radius: 18px;
    min-width: 18px;
    height: 18px;
    padding: 0 3px;
    line-height: 18px;
    vertical-align: middle;
    text-align: center;
    font-size: 0.75rem;
    font-weight: 500;
}

/*  End Sidebar Contact List */

@media screen and (min-width: 1000px) and (max-width: 1300px) {
    .sidebar {
        flex: 35%;
        height: 100% !important;
        overflow-y: hidden;
    }

    .sidebar~div {
        flex: 65%;
        height: 100% !important;
    }
}

@media screen and (min-width: 1301px) {
    .sidebar {
        flex: 30%;
        height: 100% !important;
        overflow-y: hidden;
    }

    .sidebar~div {
        flex: 70%;
        height: 100% !important;
    }
}